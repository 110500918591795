html, body, #root {
    height: 100%;
    background: #000;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    scroll-behavior: smooth;
    max-width: 100%;
}

a {
    text-decoration: none !important
}

h1 {
    font-size: 4rem;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
}

.gradient {
    background: linear-gradient(45deg, #4c75c0, #6a5bae);
}

.text-purple {
    color: #6a5bae !important;
}

.text-blue {
    color: #4c75c0 !important;
}

.text-grey {
    color: #696969 !important;
}

.text-black {
    color: #000000 !important;
}

@keyframes arrow_move {
    from {
        margin-bottom: 10px;
    }
    to {
        margin-bottom: 20px;
    }
}

.bg-black {
    background: black;
}

.bg-white {
    background: white;
}

.noselect {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
}

/*** SOMMAIRE ***/

#summary {
    display: flex;
    -webkit-justify-content: center;
    width: 100%;
    color: #fff;
    position: fixed;
    top: 30px;
    right: 0;
    left: 0;
    z-index: 1030;
    font-size: 18px;
}

#summary > div {
    padding: .5rem .2rem;
    background-color: #27282c;
    border-radius: .5rem;
    display: flex;
    -webkit-flex-wrap: wrap;
    margin: 0 -.3rem;
    box-shadow: 0 5px 25px 0 rgb(0 0 0 / 50%);
}

#summary > div > p {
    background-color: #27282c;
    padding: .25rem .5rem 0.15rem .5rem;
    border-radius: .3rem;
    margin: 0 .3rem;
    cursor: pointer;
    color: white !important;
    transition: all .1s;
}

#summary > div > p.active {
    background-color: #FFFFFF;
    color: #343a40 !important;
    transition: all .1s;
}

/*** ACCUEIL ***/

#accueil {
    overflow: hidden;
    position: relative;
}

#accueil .col {
    display: flex;
}

#face-container {
    position: relative;
    height: 90vh;
    object-fit: contain;
    margin: auto auto 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 50px;
}

#face {
    margin: 100px;
    background: linear-gradient(45deg, #4c75c0, #6a5bae);;
    border-radius: 1000px;
    max-height: 700px;
    width: auto;
    height: auto;
}

#bio-container {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    text-align: left;
    margin: auto 0 auto auto;
    padding-top: 50px;
}

#bio-container h2 {
    font-family: 'Poppins', sans-serif;
    font-weight: 300;
    font-size: 45px;
    background: linear-gradient(45deg, #4c75c0, #6a5bae);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

#bio-container h3 {
    padding-top: 10px;
    color: #5c5c5c;
}

#accueil .tentacle1 {
    position: absolute;
    left: 180px;
    transform: rotate(110deg);
    bottom: calc(50% - 550px);
    width: 250px;
}

#accueil .tentacle2 {
    position: absolute;
    left: 180px;
    transform: rotate(80deg);
    top: calc(50% - 550px);
    width: 100px;
}

#accueil .obscrax {
    position: absolute;
    right: -600px;
    transform: rotate(-90deg);
    top: calc(50% - 410px);
    width: 700px;
}

/*** PARCOURS ***/

#parcours {
    padding: 2.5rem;
    background: #FFF;
    position: relative;
    overflow: hidden;
}

#parcours .col {
    display: flex;
}

#road {
    position: absolute;
    overflow: hidden;
    top: calc(50% - 340px);
    left: 250px;
    -webkit-transform: rotateZ(70deg) rotateX(30deg) rotateY(-50deg) scale(2.5);
}

#parcours-container {
    position: relative;
    max-width: 600px;
    margin: auto;
    padding-bottom: 20%;
    display: flex;
    flex-direction: column;
}

#parcours-container h1 {
    margin-bottom: 10px;
    background: linear-gradient(45deg, #4c75c0, #6a5bae);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

#parcours-container h3 {
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: 1.78vw;
    line-height: 1.4;
}

.responsive-content {
    width: 100%;
    height: auto;
}

.step-icon {
    position: absolute;
    top: -12px;
    right: 9px;
}

.step-icon svg {
    position: absolute;
}

.step-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 110px;
    width: 110px;
    padding: 5px;
    border-radius: 10px;
    border: 3px solid #dbdbdb;
    position: absolute;
    background-color: white;
    transition: all .2s;
    -webkit-transform: rotateX(30deg) rotateY(-36deg);
    cursor: pointer;
}

.step-container.up:after {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    width: 0;
    height: 0;
    border: 20px solid transparent;
    border-bottom-color: #dbdbdb;
    border-top: 0;
    margin-left: -20px;
    margin-top: -22px;
}

.step-container.down:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 0;
    height: 0;
    border: 20px solid transparent;
    border-top-color: #dbdbdb;
    border-bottom: 0;
    margin-left: -20px;
    margin-bottom: -22px;
}

.step-container h1 {
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-size: 1rem !important;
    margin-bottom: 2px;
}

.step-container h2 {
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    color: #5c5c5c;
    font-size: 1rem;
    margin-bottom: 0;
}

.step-container.first {
    top: calc(50% + 192px);
    left: 70px;
}

.step-container.second {
    top: calc(50% - 74px);
    left: 60px;
}

.step-container.third {
    top: calc(50% + 116px);
    left: 280px;
}

.step-container.fourth {
    top: calc(50% - 151px);
    left: 271px;
}

.step-container.fifth {
    top: calc(50% + 40px);
    left: 490px;
}

#responsive-step {
    margin-left: 40px;
}

/*** COMPETENCES ***/

#competences {
    height: 100%;
    background: black;
    position: relative;
    color: white;
    overflow: hidden;
}

#competences > div {
    max-height: 100%;
    position: relative;
    z-index: 1;
}

.category-btn {
    min-height: 220px;
    max-height: 220px;
    min-width: 220px;
    max-width: 220px;
    background: linear-gradient(45deg, #4c75c0, #6a5bae);
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: 2rem;
    margin: 10px;
    cursor: pointer;
    position: relative;
    top: 0;
    transition: .3s ease;
    border-radius: 20px;
}

.category-btn:hover {
    top: 20px;
    transition: .3s ease;
}

.gradient-div {
    position: absolute;
    background: #27282c;
    opacity: 1;
    min-width: 100%;
    min-height: 100%;
    transition: .3s ease;
    border-radius: 20px;
}

#category-content {
    position: relative;
    top: 0;
    background: white;
    text-align: left;
    padding-top: 0;
    max-height: 0;
    min-width: 940px;
    max-width: 940px;
    transition: .3s ease;
    overflow: hidden;
    color: black;
    border-radius: 20px;
}

#category-content h3 {
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: 1.5rem;
}

#category-content h4 {
    font-family: 'Poppins', sans-serif;
    font-weight: 200;
    font-size: 1.2rem;
}

#competences .tentacle3 {
    position: absolute;
    right: 360px;
    -webkit-transform: rotate(-130deg);
    bottom: -200px;
    width: 250px;
}

/*** EXPERIENCE ***/

#experience {
    height: 100%;
    background: #FFF;
    position: relative;
    overflow: hidden;
    color: black;
}

#experience > div {
    position: relative;
    z-index: 1;
}

.experiences {
    margin: 0 -20px 0 -20px;
}

.experiences > .col {
    margin: 0 20px 0 20px;
}

#laprovence {
    background: linear-gradient(#0c4da1, #2b88ff);
    color: white;
}

#laprovence-top {
    background: #0c4da1;
}

#witivio {
    background: linear-gradient(#7a2954, #a13b71);
    color: white;
}

#witivio-top {
    background: #7a2954;
}

#linkedin {
    background: linear-gradient(#004f7a, #008edb);
    color: white;
}

#linkedin-top {
    background: #004f7a;
}

#experience .card {
    width: 300px;
}

.card {
    text-align: left;
    box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.5);
    cursor: pointer;
    transition: all .2s;
    border-radius: 20px;
    border: none !important;
    margin: 15px;
    background: transparent;
}

.card:hover {
    -webkit-transform: scale(1.1);
    box-shadow: 0 5px 30px 0 rgba(0, 0, 0, 0.3);
}

.card > .top {
    background: black;
    color: #fff !important;
    padding: 1rem 1rem 0 1rem;
    text-align: center;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}

.card > .body {
    padding: 1rem;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    height: 100%;
}

.card > .body > .date {
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: 35px;
    text-align: center;
}

.card > .body > .stage {
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
}

.card > .body > .desc {
    margin-bottom: 0;
    font-family: 'Poppins', sans-serif;
    font-weight: 300;
}

#experience .tentacle2 {
    position: absolute;
    left: 390px;
    -webkit-transform: rotate(110deg);
    top: -50px;
    width: 130px;
}

/*** REALISATIONS ***/

#realisations {
    height: 100%;
    background: black;
    position: relative;
    color: white;
    overflow: hidden;
}

#realisations > div {
    max-height: 100%;
    position: relative;
    z-index: 1;
}

#casino {
    background: linear-gradient(162deg, #a160cc, #fc5e8c);
}

#realisations h4.date {
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: 35px;
}

#realisations .card {
    background: transparent;
    color: black;
    width: 300px;
    height: 300px;
}

#realisations .row .col {
    display: flex;
    justify-content: center;
}

#realisations .card div {
    height: 100%;
    display: flex;
    -webkit-flex-direction: column;
    flex-direction: column;
    -webkit-align-items: center;
    align-items: center;
    justify-content: center;
    background: #FFFFFF;
    border-radius: 20px;
}

#realisations .tentacle1 {
    position: absolute;
    left: 280px;
    bottom: -320px;
    width: 350px;
}

/*** CONTACT ***/

#contact {
    height: 100%;
    background: white;
    position: relative;
    overflow-y: scroll;
    overflow-x: hidden;
}

#realisations > div {
    max-height: 100%;
    position: relative;
    z-index: 1;
}

.contact-item {
    background-color: #27282c;
    color: #FFFFFF !important;
    border-radius: 50px;
    box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.5);
    transition: all .2s;
    font-size: 1.75rem;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    padding: 5px 12px;
}

.contact-item:hover {
    color: #FFFFFF !important;
}

.cv {
    transition: all .2s;
    cursor: pointer;
    box-shadow: 0 5px 25px 0 rgb(0 0 0 / 50%);
}

.contact-item:hover, .cv:hover {
    -webkit-transform: scale(1.1);
    box-shadow: 0 5px 30px 0 rgba(0, 0, 0, 0.3);
}

.contact-item > img {
    width: 30px;
    height: 30px;
    border-radius: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 5px 0 0;
}

.contact-item > div {
    width: 30px;
    height: 30px;
    color: black;
    background-color: #FFFFFF;
    border-radius: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 5px 0 0;
}

.contact-item > div > svg {
    width: 23px;
    height: 23px;
}

#fake-at {
    background-color: transparent;
    color: white;
    margin: 0 -5px 0 -3px;
}

/*** MOBILE ***/
@media (max-width: 767px) {

    h1 {
        font-size: 3rem !important;
    }

    .h-100 {
        height: 100vh !important;
    }

    /*** SOMMAIRE ***/
    #summary {
        top: auto;
        right: 20px;
        bottom: 20px;
        left: auto;
        min-width: 50px;
        max-width: 50px;
        min-height: 50px;
        max-height: 50px;
        border-radius: 50px;
        background-color: #27282c;
        font-size: 22px;
        overflow: hidden;
    }

    @-webkit-keyframes webkit_fadeInStatic {
        from {
            max-width: 175px;
        }
        to {
            max-width: 50px;
        }
    }

    #summary.opened {
        max-width: 175px;
        max-height: 100%;
        border-radius: 10px 10px 25px 25px;
    }

    #summary > div {
        flex-direction: column;
        padding: 0 0 10px 0;
    }

    #summary > div, #summary.opened > img {
        display: none !important;
    }

    #summary.opened > div, #summary > img {
        display: flex !important;
    }

    #summary > div > p {
        margin: 10px;
    }

    /*** ACCUEIL ***/
    #accueil .tentacle1 {
        left: calc(50% + 100px);
        -webkit-transform: rotate(-100deg);
        top: calc(32% - 360px);
        width: 260px;
        z-index: 1;
    }

    #accueil > .col:first-of-type {
        order: 12;
        margin: 0;
        height: 35%;
    }

    #bio-container {
        width: 100%;
        text-align: center;
        margin: 0 auto auto auto;
        padding-top: 30px;
    }

    #bio-container h1 {
        font-size: 50px;
    }

    #bio-container h2 {
        font-size: 30px;
    }

    #bio-container h3 {
        font-size: 20px;
    }

    #face-container {
        height: auto;
        padding: 30px;
        margin: auto auto 0 auto;
    }

    #face {
        height: 100%;
        width: 100%;
        max-height: 330px;
        max-width: 330px;
        margin: auto auto 0 auto;
    }

    /*** PARCOURS ***/
    #parcours {
        padding: 1rem;
    }

    #parcours > .col:first-of-type {
        height: 35%;
    }

    #parcours-container {
        margin: calc(30vh - 150px) auto 0 auto;
    }

    #parcours-container h3 {
        font-size: 20px;
        text-align: left;
    }

    #responsive-step {
        height: 65%;
        -webkit-transform: scale(0.6);
        left: calc(30% - 195px);
        margin-left: 0;
    }

    /*** COMPETENCES ***/
    .category-btn {
        min-height: 50px;
        max-height: 50px;
        min-width: 80vw;
        max-width: 80vw;
        margin: 5px;
        border-radius: 10px;
        font-size: 1.5rem;
        background: #27282c;
    }

    .category-btn:hover {
        top: 0;
        transition: none;
    }

    #competences #buttons-container .animated {
        margin: 5px;
    }

    .gradient-div {
        border-radius: 10px;
    }

    #competences .tentacle3 {
        right: 179px;
        -webkit-transform: rotate(-150deg);
        bottom: -110px;
        width: 220px;
    }

    #category-content {
        max-height: 100%;
        min-width: 0;
        max-width: 100%;
        transition: .3s ease;
        margin-top: -.5rem;
        margin-bottom: -.5rem;
    }

    #category-content h1 {
        width: 100%;
        text-align: center;
        margin: .5rem 0 0 0;
        padding-bottom: 8px;
        font-size: 40px !important;
        background: linear-gradient(45deg, #4c75c0, #6a5bae);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    /*** EXPERIENCE ***/
    .mySwipe {
        margin: 0 auto;
    }

    .mySwipe > div {
        display: flex;
    }

    .card {
        margin: 0;
        box-shadow: none;
        padding: 0 10px;
    }

    .card:hover {
        -webkit-transform: none;
        box-shadow: none;
    }

    /*** CONTACT ***/
    .contact-items {
        margin-right: 0;
        margin-bottom: 20px;
    }
}
