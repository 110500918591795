body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
    font-family: 'heavy';
    src: url('fonts/heavy.eot') format('embedded-opentype'),
    url('fonts/heavy.eot?#iefix') format('embedded-opentype'),
    url('fonts/heavy.woff') format('woff'),
    url('fonts/heavy.ttf') format('truetype'),
    url('fonts/heavy.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'black';
    src: url('fonts/black.eot') format('embedded-opentype'),
    url('fonts/black.eot?#iefix') format('embedded-opentype'),
    url('fonts/black.woff') format('woff'),
    url('fonts/black.ttf') format('truetype'),
    url('fonts/black.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'medium';
    src: url('fonts/medium.eot') format('embedded-opentype'),
    url('fonts/medium.eot?#iefix') format('embedded-opentype'),
    url('fonts/medium.woff') format('woff'),
    url('fonts/medium.ttf') format('truetype'),
    url('fonts/medium.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'roman';
    src: url('fonts/roman.eot') format('embedded-opentype'),
    url('fonts/roman.eot?#iefix') format('embedded-opentype'),
    url('fonts/roman.woff') format('woff'),
    url('fonts/roman.ttf') format('truetype'),
    url('fonts/roman.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'light';
    src: url('fonts/light.eot') format('embedded-opentype'),
    url('fonts/light.eot?#iefix') format('embedded-opentype'),
    url('fonts/light.woff') format('woff'),
    url('fonts/light.ttf') format('truetype'),
    url('fonts/light.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}